<template>
  <div class="menu">
    <p class="title">管理系统</p>
    <div class="menu-content">
      <template v-for="item in menuList">
        <div
          class="menu-content-item pointer"
          v-if="
            item.authority == 'all' || item.authority.indexOf(user.role) != -1
          "
          @click="$router.push({ path: item.name })"
          :class="
            defaultActive == item.name ? 'active ' + item.class : item.class
          "
          :key="item.name"
        >
          <i
            :class="defaultActive == item.name ? item.activeIcon : item.icon"
          ></i>
          <div>{{ item.title }}</div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    // 		角色
    //0 - 管理员;
    //1 - 用户;
    //2 - 审批人;
    //3 - 执行人;
    return {
      menuList: [
        // {
        //   title: "首页",
        //   name: "/home",
        //   class: "home",
        //   icon: "el-icon-ali-a-shouye2",
        //   activeIcon: "el-icon-ali-shouye",
        //   authority: "all",
        // },
        {
          title: "报单",
          name: "/task",
          class: "task",
          icon: "el-icon-ali-a-shouye2",
          activeIcon: "el-icon-ali-shouye",
          authority: "all",
        },
        {
          title: "账号管理",
          name: "/accountManagement",
          class: "accountManagement",
          icon: "el-icon-ali-zhanghaoguanli2",
          activeIcon: "el-icon-ali-zhanghaoguanli",
          authority: "0",
        },
      ],
      defaultActive: "/home",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.userInfo.user,
      collapse: (state) => state.collapse,
    }),
  },
  mounted() {},
  methods: {},
  watch: {
    "$route.meta": {
      handler({ menu }) {
        this.defaultActive = "/" + menu;
      },
      immediate: true,
    },
  },
};
</script>
<style lang='scss' scoped>
.active {
  background: #051752;
  opacity: 1 !important;
  border-radius: 4px;
}
.menu {
  background: #12245c;
  width: 96px;
  height: 100%;
  position: relative;
  .title {
    width: 96px;
    height: 60px;
    color: #ffffff;
    font-size: 12px;
    position: fixed;
  }
  &-content {
    overflow-y: auto;
    height: calc(100% - 60px);
    &::-webkit-scrollbar {
      width: 0 !important;
    }
    width: inherit;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    color: #fff;
    top: 60px;

    &-item {
      flex-shrink: 0;
      width: 80px;
      height: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      opacity: 0.8;
      line-height: 21px;
      i {
        margin-bottom: 4px;
      }
      &:hover {
        @extend .active;
      }
    }
    // .outerCustomerManagement,
    // .outerCaseManagement {
    //   color: #f8a3a3;
    // }
  }
}
.el-menu {
  border-right-width: 0;
}
</style>