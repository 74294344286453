/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-08 10:07:54
 * @Module: 账号管理
 */
 <template>
  <div class="AccountManagement">
    <!-- <div class="top">
      <searchForm :searchForm.sync="listParams" @submit="getAccountList" />
    </div> -->

    <div class="content">
      <div class="title">
        <span>账号列表</span>
        <div>
          <el-button type="primary" size="small" @click="add">添加账号</el-button>
        </div>
      </div>
      <div class="main">
        <el-table size="mini" :data="list" tooltip-effect="dark" style="width: 100%;">
          <!-- <el-table-column type="expand">
            <template slot-scope="prop">
              <div style="padding: 0 60px">
                <el-descriptions size="mini" :column="1" :colon="false">
                  <el-descriptions-item label="常住地：">{{prop.row.contact||"--"}}</el-descriptions-item>
                  <el-descriptions-item label="联系方式：">{{prop.row.mobile||"--"}}</el-descriptions-item>
                  <el-descriptions-item label="微信号：">{{prop.row.wx||"--"}}</el-descriptions-item>
                  <el-descriptions-item label="生日：">
                    <span v-if="prop.row.birthday">{{prop.row.birthday|timeFormat}}</span>
                    <span v-else>--</span>
                  </el-descriptions-item>
                </el-descriptions>
              </div>
            </template>
          </el-table-column> -->
          <el-table-column label="用户名" show-overflow-tooltip prop="name">
            <template slot-scope="scope">
              <span>{{ scope.row.name||"--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="角色" show-overflow-tooltip prop="role">
            <template slot-scope="scope">
              <span v-if="scope.row.role==3" style="color:#0D72FF">执行人</span>
              <span v-else-if="scope.row.role==0" style="color:#0AC258">管理员</span>
              <span v-else-if="scope.row.role==1" style="color:#262626">用户</span>
              <span v-else-if="scope.row.role==2" style="color:#E6A23C">审批人</span>
              <!-- <span v-else-if="scope.row.role==3" style="color:#993926">外部管理员</span> -->
              <!-- <span v-else-if="scope.row.role==4" style="color:#E6A23C">联盟会员</span>
              <span v-else-if="scope.row.role==5" style="color:#262626">律师</span> -->
            </template>
          </el-table-column>
        
          <el-table-column label="登录手机号" show-overflow-tooltip prop="phone">
            <template slot-scope="scope">
              <span>{{ scope.row.phone ||"--"}}</span>
            </template>
          </el-table-column>

         
          <el-table-column label="联系人" show-overflow-tooltip>
            <template slot-scope="scope">
              {{scope.row.contact||"--"}}
            </template>
          </el-table-column>
          <el-table-column label="联系电话" show-overflow-tooltip>
            <template slot-scope="scope">
              {{scope.row.mobile||"--"}}
            </template>
          </el-table-column>
          <el-table-column label="微信号" show-overflow-tooltip>
            <template slot-scope="scope">
              {{scope.row.wx||"--"}}
            </template>
          </el-table-column>
          <el-table-column label="常驻地" show-overflow-tooltip>
            <template slot-scope="scope">
              {{scope.row.address||"--"}}
            </template>
          </el-table-column>
          <el-table-column label="创建时间" show-overflow-tooltip prop="createdAt">
            <template slot-scope="scope">
              <span>{{ scope.row.createdAt |timeFormat('yyyy-mm-dd hh:MM:ss')  }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="60" :resizable="false">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="()=>revision(scope.row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <c-pagination ref="pagination" layout="prev, pager, next,sizes" @change="getAccountList" />
    </div>

    <accountManagementForm @success="getAccountList" ref="accountManagementForm" />

  </div>
</template>
 <script>
import accountManagementForm from "@/views/AccountManagement/components/accountManagementForm"
// import searchForm from "@/views/AccountManagement/components/searchForm"
export default {
  components: {
    accountManagementForm,
    // searchForm
  },
  data () {

    return {

      list: [],
      listParams: {
        keyword: '',
        role: ''
      }
    };
  },
  mounted () {
    this.getAccountList()
  },
  methods: {
    add () {
      this.$refs.accountManagementForm.show({ type: 'add' });
    },
    revision (data) {
      this.$refs.accountManagementForm.show({ type: 'revision', data });
    },
    async getAccountList () {
      const { current, size } = this.$refs.pagination
      const { listParams } = this
      const { status, data } = await this.$api.getAccountList({ current, size, ...listParams })
      if (status == 200) {
        this.list = data.records;
        this.$refs.pagination.createPagination(data)
      }

    }
  },
};
 </script>
 <style lang='scss' scoped>
.AccountManagement {
  .top {
    height: 68px;
    overflow: hidden;
    background: #fff;
    padding: 0 16px;
  }
  .content {
    background: #fff;
    padding: 16px;
    padding-top: 0;
    .title {
      display: flex;
      line-height: 56px;
      justify-content: space-between;
    }
  }
}
</style>