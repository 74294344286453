/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-06 12:55:31
 * @Module: 登录
 */
 <template>
  <div class="login">
    <div class="login-wrap" v-show="type==='login'">
      <div class="login-container">
        <div class="bg">
          <p>欢迎使用</p>
          <p style="margin-top:6px;">报单审批系统</p>
        </div>
        <div class="loginForm">
          <p class="des">手机账号登录</p>
          <el-form label-position="top" hide-required-asterisk :model="loginForm" :rules="loginRules" ref="loginForm" class="main">
            <el-form-item prop="phone" label="手机号">
              <el-input type="text" v-model="loginForm.phone" auto-complete="off" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item prop="verifyCode" label="验证码">
              <el-input @keyup.enter.native="submitForm" type="text" v-model="loginForm.verifyCode" auto-complete="off" placeholder="请输入验证码">
                <i slot="suffix" style="font-size: 14px;color:#0062FF;margin-right:15px;" class="pointer" @click="getcode">{{secondrender}}</i>
              </el-input>
            </el-form-item>
            <el-form-item style="width:100%;margin-top: 100px;">
              <el-button type="primary" style="width:100%;" @click="submitForm" :loading="logining">登录</el-button>
            </el-form-item>
          </el-form>
        </div>

      </div>

    </div>
  </div>
</template>
<script scoped>
//17703887474 3153
export default {
  name: 'Login',
  data () {
    const phoneValidator = (_, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'));
      }
      if (this.$utils.test.mobile(value)) {
        return callback();
      } else {
        return callback(new Error('请输入正确的手机号'));
      }
    }
    return {
      type: 'login',

      logining: false,
      second: 0,
      loginForm: {
        phone: '',
        verifyCode: '',
      },
      //loginRules前端验证
      loginRules: {
        phone: [{ validator: phoneValidator, trigger: 'blur' }],
        verifyCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 4, max: 4, message: '请输入正确的验证码', trigger: 'blur' }
        ],
      }
    }
  },
  mounted () {

  },
  computed: {
    secondrender () {
      return this.second == 0 ? '获取验证码' : this.second + 's';
    }
  },
  methods: {
    secondrun () {
      let js = setInterval(() => {
        this.second--;
        if (this.second == 0) {
          clearInterval(js);
        }
      }, 1000);
    },
    async getcode () {
      if (this.second !== 0) {
        return;
      }
      const { status, info } = await this.$api.postSmsCodeLogin({ phone: this.loginForm.phone });
      if (status == 200) {
        this.$message({
          message: info,
          type: 'success'
        });
        this.second = 60;
        this.secondrun();
      } else {
        this.$message({
          message: info,
          type: 'error'
        });
      }
    },

    //提交
    submitForm () {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.postUserLogin()
        } else {
          return false;
        }
      });
    },
    setUserInfo (data) {
      this.$store.dispatch("userInfo/setUserInfo", data)
      if (this.$route.query.redirect) {
        let redirect = this.$route.query.redirect;
        this.$router.push(redirect);
      } else {
        this.$router.push('/');
      }
    },
    async postUserLogin () {
      const { info, status, data } = await this.$api.postLogin(this.loginForm)
      if (status === 200) {
        this.$message({
          message: info,
          type: 'success'
        })
        this.setUserInfo(data)
      } else {
        this.$message({
          message: info,
          type: 'error'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/* 当页面宽度小于300px*/
@keyframes example {
  from{
		background-position: center left;
   
  }

	to{
		background-position: center right;
	}
}
.login {
  display: flex;
  height: 100vh;
  background: #fcfcfc;
  background-image: url(~@/static/img/login_bg.png);
  background-position: center center;
  background-size: auto;
  background-repeat: no-repeat;
  animation-name: example;
  animation-direction: alternate;
  animation-duration: 60s;
	animation-iteration-count:infinite;
}

.login-wrap {
  box-sizing: border-box;
  // width: calc(100vw - 540px);
  height: 100vh;
  display: flex;
  align-items: center;
  background-size: 429px 482px;
  background-repeat: no-repeat;
  background-position: top right;
  justify-content: center;
  flex: 1;
}
.login-container {
  position: absolute;
  width: 884px;
  height: 492px;
  left: calc(50% - 884px / 2);
  top: calc(50% - 492px / 2);

  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  display: flex;

  .bg {
    width: 442px;
    height: 492px;
    background-image: url(~@/static/img/login_bg2.png);
    p {
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      /* identical to box height */

      color: #ffffff;
      margin-top: 60px;
      margin-left: 40px;
    }
  }
  .loginForm {
    width: 442px;
    height: 492px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .des {
      width: 362px;
      font-size: 22px;
      font-weight: 500;
      color: #262626;
      line-height: 50px;
      margin-top: 50px;
    }
    .main {
      width: 362px;
      margin-top: 20px;
    }
    .btn {
      // display: flex;
      // justify-content: flex-end;
    }
  }
}
</style>
