import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Home from '@/views/Home.vue' // 主页
import Task from '@/views/Task.vue' // 报单
import CaseManagement from '@/views/CaseManagement.vue' // 内部案件
import Contacts from '@/views/Contacts.vue' // 辖区联络
import CustomerSearch from '@/views/CustomerSearch.vue' // 客户检索
import Earnings from '@/views/Earnings.vue' // 分账明细
import CustomerManagement from '@/views/CustomerManagement.vue' // 内部客户
import AccountManagement from '@/views/AccountManagement.vue' // 账号管理
import OuterCustomerManagement from '@/views/OuterCustomerManagement.vue' // 外部客户
import OuterCaseManagement from '@/views/OuterCaseManagement.vue' // 外部案件
import WithdrawApplication from '@/views/WithdrawApplication.vue' // 提现申请
import Commission from '@/views/Commission.vue' // 分佣列表
import Deal from '@/views/Deal.vue' // 成单记录
import Promoter from '@/views/Promoter.vue' // 联盟会员
import CustomerIntended from '@/views/CustomerIntended.vue' // 联盟会员



import Login from '@/views/Login.vue' // 主页
import defaultLayouts from '@/layouts/default'
import { Message } from 'element-ui';
Vue.use(VueRouter)
// 重复跳转报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
	return originalPush.call(this, location).catch(err => err)
}

const routes = [
	{
		path: '/',
		component: defaultLayouts,
		redirect: '/task',
		children: [
			// {
			// 	path: '/home',
			// 	name: 'home',
			// 	component: Home,
			// 	meta: {
			// 		menu: 'home',
			// 		transparency: true,
			// 		title: '首页'
			// 	}
			// },
			{
				path: '/task',
				name: 'task',
				component: Task,
				meta: {
					menu: 'task',
					transparency: true,
					title: '报单'
				}
			},
			{
				path: '/accountManagement',
				name: 'accountManagement',
				component: AccountManagement,
				meta: {
					menu: 'accountManagement',
					title: '账号管理',
					transparency: true,

				}
			},
			{
				path: '/caseManagement',
				name: 'caseManagement',
				component: CaseManagement,
				meta: {
					menu: 'caseManagement',
					title: '内部案件',
					transparency: true,
				}
			},
			{
				path: '/outerCaseManagement',
				name: 'outerCaseManagement',
				component: OuterCaseManagement,
				meta: {
					menu: 'outerCaseManagement',
					title: '外部案件',
					transparency: true,
				}
			},
			{
				path: '/contacts',
				name: 'contacts',
				component: Contacts,
				meta: {
					menu: 'contacts'
				}
			},
			{
				path: '/withdrawApplication',
				name: 'withdrawApplication',
				component: WithdrawApplication,
				meta: {
					menu: 'earnings'
				}
			},
			{
				path: '/customerSearch',
				name: 'customerSearch',
				component: CustomerSearch,
				meta: {
					menu: 'customerSearch',

					// title: '客户检索'
				}
			},
			{
				path: '/earnings',
				name: 'earnings',
				component: Earnings,
				meta: {
					menu: 'earnings',
					title: '分账明细',
					transparency: true,
				}
			},
			{
				path: '/commission',
				name: 'commission',
				component: Commission,
				meta: {
					menu: 'commission',
					title: '佣金明细',
					transparency: true,
				}
			},
			{
				path: '/withdrawAppProcess',
				name: 'withdrawAppProcess',
				component: () => import('@/views/Commission/withdrawAppProcess'),
				meta: {
					menu: 'commission'
				}
			},
			{
				path: '/deal',
				name: 'deal',
				component: Deal,
				meta: {
					menu: 'deal',
					title: '成单记录',
					transparency: true,
				}
			},
			{
				path: '/promoter',
				name: 'promoter',
				component: Promoter,
				meta: {
					menu: 'promoter',
					title: '联盟会员',
					transparency: true,
				}
			},
			{
				path: '/customerManagement',
				name: 'customerManagement',
				component: CustomerManagement,
				meta: {
					menu: 'customerManagement',
					title: '内部客户',
					transparency: true,
					keepAlive: true // 需要缓存页面
				}
			},
			{
				path: '/customerManagement/:uuid',
				name: 'customerManagementDetail',
				component: () => import('@/views/CustomerManagement/CustomerManagementDetail'),
				meta: {
					menu: 'customerManagement',
					transparency: true,

				}
			},
			{
				path: '/outerCustomerManagement',
				name: 'outerCustomerManagement',
				component: OuterCustomerManagement,
				meta: {
					menu: 'outerCustomerManagement',
					title: '外部客户',
					transparency: true,
					keepAlive: true // 需要缓存页面
				}
			},
			{
				path: '/outerCustomerManagement/:uuid',
				name: 'outerCustomerManagementDetail',
				component: () => import('@/views/OuterCustomerManagement/OuterCustomerManagementDetail'),
				meta: {
					menu: 'outerCustomerManagement',
					transparency: true,

				}
			},
			{
				path: '/trend/:uuid',
				name: 'trend',
				component: () => import('@/views/DynamicState'),
				meta: {
					menu: 'trend'
				}
			},
			{
				path: '/icase',
				name: 'icase',
				component: () => import('@/views/Icase'),
				meta: {
					menu: 'icase',
					title: '个案管理',
					transparency: true,
				}
			},
			{
				path: '/customerIntended',
				name: 'customerIntended',
				component: CustomerIntended,
				meta: {
					menu: 'customerIntended',
					title: '意向客户',
					transparency: true,
					keepAlive: true // 需要缓存页面
				},

			},
			{
				path: '/customerIntended/search',
				name: 'customerIntendedSearch',
				component: () => import('@/views/CustomerIntended/customerIntendedSearch'),
				meta: {
					menu: 'customerIntended',
				},
			}
		]
	},
	{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: '*',
		name: 'error_404',
		component: () => import('@/views/404'),
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})
router.beforeEach((to, from, next) => {
	const { token, role } = store.state.userInfo.user
	// if (to.matched.some(record => record.meta.requireAuth)) {  // 判断该路由是否需要登录权限
	if (token) {  // 判断当前的token是否存在
		if (role == 'OUTER' && to.name === 'home') {
			next({
				path: '/icase'
			})
		} else if (role == 'PROMOTER' && to.name === 'home') {
			next({
				path: '/promoter'
			})
		} else if (role == 'LAWYER' && to.name === 'home') {
			next({
				path: '/caseManagement'
			})
		} else {
			next();
		}
	} else if (to.name === 'login') {
		next();
	} else {
		next({
			path: '/login',
			query: { redirect: to.fullPath }  // 将跳转的路由path作为参数，登录成功后跳转到该路由
		})
	}

})
export default router
